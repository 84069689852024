<template>
  <div class="terms">
    <div class="terms__content">
      <h2 class="terms__heading">Nutzungsbedingungen</h2>
      <p>
        Hier findest du in Kürze die Nutzungsbedingungen für unsere Plattform.
      </p>
    </div>
    <app-footer class="terms__footer"></app-footer>
  </div>
</template>

<script>
import AppFooter from "@/components/Templates/Footer.vue";

export default {
  name: "Terms",
  components: {
    AppFooter
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.terms {
  // display: none !important;
  position: relative;
  padding-bottom: 12rem;
  // min-height: calc(100vh + 6rem);
  // height: calc(100vh - 18rem);
  min-height: 100vh;

  display: grid;
  grid-template-columns: [margin-left-start] 1fr [margin-left-end center-start] $width-center [center-end margin-right-start] 1fr [margin-right-end];

  color: $color-white;
  font-size: 2.4rem;

  @include respond(tab-land) {
    grid-template-columns: [margin-left-start] 3rem [margin-left-end center-start] 1fr [center-end margin-right-start] 3rem [margin-right-end];
  }

  @include respond(phone) {
    grid-template-columns: [margin-left-start] 1.5rem [margin-left-end center-start] 1fr [center-end margin-right-start] 1.5rem [margin-right-end];
  }
}

.terms__content {
  grid-column: 2 / 3;
  margin-top: 10rem;
  margin-bottom: 10rem;
  width: 100%;
}

a {
  // color: $color-primary-old-light-1;
  color: #7cccff;
}

.terms__heading {
  margin-bottom: 2.4rem;
}

.terms__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $color-black;
}
</style>
